.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

/* Optional: style the waitlist container and list items */
.waitlist {
  padding: 1rem;
}

.waitlist li {
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
